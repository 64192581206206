html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font: 16px/1.618 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'whitman-display', serif;
  font-weight: normal;
}
a {
  color: #0b1f03;
  outline: none;
  text-decoration: none;
  transition: 0.2s color;
}
a:hover, a:active {
  color: #d6ae71
}
button {
  outline: none;
}
input, textarea {
  font: 16px/1.618 'Lato', sans-serif;
  outline: none;
}
button, input {
  border-radius: none;
  -webkit-appearance: none;
}
